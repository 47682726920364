import Link from "next/link";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import { HomePageProps } from "../@types/content-types";
import Footer from "../components/Footer";
import MainMenu from "../components/MainMenu";

function Home({ content }: HomePageProps): ReactElement {
  const router = useRouter();
  const [id, setId] = useState<string[]>([]);
  const [videoHeight, setVideoHeight] = useState<number>();
  const { footer } = content;
  const { mp4, title, subtitle, how } = content.homePage;
  const { who, info, call, proposition } = content.homePage;

  useEffect(() => {
    const el = document.getElementById("video");

    setVideoHeight((el?.getBoundingClientRect().bottom as number) - 60);
  }, [videoHeight]);

  useEffect(() => {
    setId(who.map((item) => item.id_selector));
  }, []);

  return (
    <>
      <MainMenu />
      <section
        id="video"
        className="w-screen h-screen max-h-screen bg-gray-700 static block overflow-hidden p-0 m-0"
      >
        <div className="w-full h-full relative flex overflow-hidden">
          <div className="relative z-30 container mx-auto pt-60 landscape:pt-24 portrait:px-7">
            <h1
              className={`text-secondary uppercase mb-3 tracking-wide font-semibold text-left text-xl md:text-5xl md:w-3/5 landscape:w-full`}
            >
              {title}
            </h1>
            <h2 className="text-white text-sm md:text-lg">{subtitle}</h2>
            <div className="mt-10 w-40">
              <Link href="/signup">
                <a className="btn text-center">Join now</a>
              </Link>
            </div>
          </div>
          <video
            autoPlay={true}
            loop={true}
            playsInline={true}
            className="absolute z-10 w-auto min-w-full min-h-full max-w-none mix-blend-overlay object-cover landscape:object-contain portrait:object-cover portrait:max-w-md"
          >
            <source type="video/mp4" src={mp4} />
          </video>
        </div>
      </section>
      <section id="proposition" className="bg-primary py-10 md:py-20 w-screen">
        <div className="contianer">
          <h1 className="text-secondary text-center font-light text-2xl md:text-5xl uppercase">
            {proposition}
          </h1>
        </div>
      </section>
      <section id="about" className="p-20 bg-white w-screen">
        <div className="container">
          <h3 className="section-title">{how.desc}</h3>
          <div className="grid md:grid-cols-3 gap-10">
            {how.Col.map((element, index) => {
              return (
                <div
                  key={"home" + index}
                  className="col-span-1 text-center cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault();

                    router.push({ href: "/", hash: id[index] });
                  }}
                >
                  <div className="mx-auto max-w-xs w-full mb-8">
                    <img
                      alt={element.title}
                      className="w-full h-48 object-contain"
                      src={element?.img?.url}
                    />
                  </div>
                  <h2 className="col-title">{element.title}</h2>
                  <p>{element.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        id="info"
        className="w-screen h-5/6 lg:h-4/6 bg-gray-900 relative"
      >
        <div className="w-full h-full relative flex overflow-x-hidden">
          <div className="relative z-30 mx-auto">
            <div className="grid grid-rows-1 container py-20 sm:grid-rows-2 portrait:px-7">
              <div className="rows-span-2">
                <h1 className="text-secondary text-3xl uppercase mb-3 tracking-wide font-semibold">
                  {info.title}
                </h1>
                <h2 className="text-white">{info.desc}</h2>
              </div>
              <div className="row-start-3 mt-10">
                <div className="grid grid-cols-2 gap-10 lg:grid-cols-4">
                  {info.stats.map((stat, index) => {
                    return (
                      <div
                        key={"stat" + index}
                        className="col-span-1 p-3 md:p-6 lg:p-6 bg-white opacity-70 rounded-2xl shadow-2xl text-center"
                      >
                        <h2 className="text-primary font-light text-2xl md:text-4xl mb-3">
                          {stat.number + "%"}
                        </h2>
                        <p className="text-sm">{stat.desc}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <video
            autoPlay={true}
            loop={true}
            playsInline={true}
            className="absolute z-10 w-auto min-w-full min-h-full h-5/6 max-w-none mix-blend-overlay object-cover lg:4/6"
          >
            <source type="video/mp4" src={mp4} />
          </video>
        </div>
      </section>
      <section
        id="benefits"
        className="py-20 w-screen bg-gray-50 z-40 md:px-0 xl:px-20"
      >
        <div className="container">
          {who.map((element, index) => {
            const ltr = index % 2 !== 0;

            return (
              <div
                key={element.id_selector}
                id={element.id_selector}
                className="grid grid-cols-2 place-items-center px-5 py-10"
              >
                <div
                  className={`${
                    ltr ? "order-last" : ""
                  } cols-span-1 hidden md:block md:w-60 lg:w-80`}
                >
                  <img
                    alt={element.title}
                    className="w-full"
                    src={element.img.url}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <h1 className="split-title">{element.title}</h1>
                  <p className="mb-7 xl:mb-7">{element.desc}</p>
                  <Link href={element.url}>
                    <a className="btn">Learn more</a>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section id="cta" className="w-screen p-20 bg-primary text-center">
        <h1 className="text-secondary text-3xl mb-3">{call.title}</h1>
        <h3 className="text-white">{call.desc}</h3>
        <div className="mt-8">
          <Link href="/signup">
            <a className="btn-secondary">Join That1Thing</a>
          </Link>
        </div>
      </section>
      <Footer mission={footer.mission} photos={footer.photos} />
    </>
  );
}

export default Home;

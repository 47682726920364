import { LoadingOutlined } from "@ant-design/icons"
import axios from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import { ReactElement, useState } from "react";
import * as yup from "yup";
import { useStore } from "../data/store";
import Notification from "./Notification";

function Newsletter(): ReactElement {
  const [submitForm, setSubmitForm] = useState(false);
  const notification = useStore((state) => state.notification);
  const setNotification = useStore((state) => state.setNotification);
  const [error, setError] = useState({ isError: false, message: "" })

  return (
    <>
      <div>
        <h2 className="font-medium text-lg mb-3">Sign up for updates</h2>
        <Formik
          initialValues={{ email: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={yup.object().shape({
            email: yup.string().email(),
          })}
          onSubmit={async (values, { resetForm }) => {
            setSubmitForm(true);

            try {
              const response = await axios.post("/api/newsletter", { values });

              if (response.data.error) {
                setError({
                  isError: response.data.error,
                  message: response.data.message,
                })

                setSubmitForm(false);
              } else if (!response.data.error) {
                setNotification({
                  show: true,
                  type: "success",
                  message: "Thanks for joining our update list",
                });

                resetForm({ values: { email: "" } });
                setSubmitForm(false);
              }

            } catch (error) {
              setNotification({
                show: true,
                type: "error",
                message:
                  "error in recieving your email.",
              });

              setSubmitForm(false);
            }
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <input
                type="email"
                name="email"
                className="input-lg"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter email"
                value={values.email}
              />
              <ErrorMessage name="email">
                {(msg) => {
                  return (
                    <div className="text-red-600 text-sm -mt-4 mb-5 ml-1.5">
                      {msg}
                    </div>
                  );
                }}
              </ErrorMessage>
              {error.isError ? <div className="text-red-600 text-sm -mt-2 mb-5 ml-1.5">{error.message}</div> : null}
              <button
                disabled={submitForm ? true : false}
                className="btn"
              >
                {submitForm ? <LoadingOutlined /> : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
        {notification.show ? (
          <Notification
            type={notification.type}
            message={notification.message}
          />
        ) : null}
      </div>
    </>
  );
}

export default Newsletter;

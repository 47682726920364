import { AlertTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { ReactElement, useEffect, useState } from "react";
import { NotificationProps } from "../@types/component-types";
import { useStore, State } from "../data/store";

function Notification({ message, type }: NotificationProps): ReactElement {
  const [errors, setErrors] = useState<string[]>([]);
  const setNotification = useStore((state: State) => state.setNotification);

  if (typeof message === "object") setErrors(Object.values(message));

  setTimeout(
    () =>
      setNotification({
        show: false,
        type: "",
        message: "",
      }),
    5000
  );

  return (
    <div
      id="notification"
      className="flex space-x-3 bg-white py-3 px-3 text-sm shadow-2xl rounded-3xl fixed top-3 left-0 right-0 m-auto border-2 border-gray-100 z-50"
    >
      <div className="relative bottom-0.5">
        {type === "error" ? (
          <AlertTwoTone twoToneColor="#DC2626" />
        ) : (
          <CheckCircleTwoTone twoToneColor="#10B981" />
        )}
      </div>
      <div>{errors.length > 1 ? errors : typeof message === "string" ? message : message ? message?.message : "Sorry, there was a problem"}</div>
    </div>
  );
}

export default Notification;

import Link from "next/link";
import { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";
import Newsletter from "./Newsletter";

const smallTablet = "(min-width: 640px) and (max-width: 767px)";

function Footer({
  mission,
  photos,
}: {
  mission: { desc: string };
  photos: { url: string }[];
}): ReactElement {
  const xs = useMediaQuery({ query: "(max-width: 639px)" });
  const sm = useMediaQuery({ query: smallTablet });
  const isMobile = xs || sm;

  const menu = [
    { home: "/" },
    { about: "/about" },
    { "passion map": "/passion-map/sharedays" },
    { join: "/signup" },
    { business: "/business" },
    { nonprofit: "/nonprofit" },
    { individual: "/individual" },
    { terms: "/terms" },
    { privacy: "/privacy" },
    { FAQ: "/faq" },
    { "request demo": "/demo" },
  ];

  return (
    <footer className="bg-white">
      <div className="container p-20">
        <div className="grid md:grid-cols-2 gap-10 xl:grid-cols-4">
          <div className="col-span-1">
            <h1 className="font-medium text-lg mb-2">Mission</h1>
            <p className="text-sm italic leading-5">{mission.desc}</p>
          </div>
          <div className="col-span-1">
            <h2 className="font-medium text-lg mb-2">Quick links</h2>
            <ul className="space-y-1 capitalize grid grid-cols-2">
              {menu.map((item) => {
                const key = Object.keys(item)[0];
                const path = Object.values(item)[0];

                return (
                  <li key={key} className="text-gray-600">
                    <Link href={path}>
                      <a>{key}</a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-span-1">
            <Newsletter />
          </div>
          {isMobile ? null : (
            <div className="col-span-1">
              <div className="relative">
                <img
                  alt="photos"
                  className="h-full w-full"
                  src={photos[0].url}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
